<template>
  <div v-html="emailContent"></div>
</template>
<script>
export default {
  data() {
    return {
      emailContent: ''
    }
  },
  mounted() {
    const html = localStorage.getItem('email')
    if (html) {
      this.emailContent = html
    } else {
      this.emailContent = 'No Email from LocalStorage with given ID'
    }
  }
}
</script>
